<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<!-- START HERE -->
							<article class="article-body" id="article-body" rel="image-enlarge">
								<p>
									Our service uses the latest H264 technology which offers the best compression and quality of streams. Most of our sports channels are in
									60FPS(frames per second).
								</p>
								<p>However when changing the channel the H264 protocol may spike higher to begin showing the picture.</p>
								<p><br /></p>
								<p>Due to the above our service requires a minimum 20 Mbps internet connection.</p>
								<p>(per connection/device)</p>
								<p>If you want to watch 2 TV’s at the same time you will require a minimum 40 Mbps for optimal viewing experience.</p>
								<p><br /></p>
								<p>Please use only OUR speedtest tool before purchasing a subscription.</p>
								<p>Our speedtest will give you an accurate result of the speed your device is getting to our servers.</p>
								<p>When running speed tests, always run the speed test on the device you're using to stream/watch.</p>
								<p><br /></p>
								<p>Example: if you're using an Android Box, you will need to open the web browser on your android box and run the speedtest.</p>
								<p><br /></p>
								<p>Do not use your phone to check the speed of your android box or vice versa.</p>
								<p>
									<strong
										><span style="font-size: 14px"><br /></span
									></strong>
								</p>
								<p>
									<span style="font-size: 14px"><strong>Speedtest link:</strong></span>
								</p>
								<p>
									<a :href="$store.state.brandInfo.speedTestLink"
										><strong
											><span style="font-size: 14px">{{ $store.state.brandInfo.speedTestLink }}</span></strong
										></a
									>
								</p>
								<p><br /><br />Our speedtest is divided into 3 sections:</p>
								<p>
									<img
										src="../../../assets/images/mini-icon/speedTest.png"
										style="width: 640px"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48015246313"
									/>
								</p>
								<p>
									<span style="color: rgb(44, 130, 201)"><strong>First section</strong>: </span
									><span style="color: rgb(0, 0, 0)">Info about your connection. Your IP address, your Internet Service Provider, and country.</span>
								</p>
								<p>
									<br /><span style="color: rgb(184, 49, 47)"><strong>Second section: </strong></span
									><span style="color: rgb(0, 0, 0)">This section is the actual speed tests, which is divided into 3 main tests.</span><br /><span
										style="color: rgb(0, 0, 0)"
										>First test - CloudFront is a speedtest from a CDN of amazon (to emulate local speedtest - 1 connection)</span
									>
								</p>
								<p>
									<span style="color: rgb(0, 0, 0)">Second test - X.X.X.X Live is the server you are playing live streaming from, based on your location.</span>
								</p>
								<p>
									<span style="color: rgb(0, 0, 0)"
										>Third test - X.X.X.X VOD is the server you are playing VOD content from, again this is based on your location.</span
									>
								</p>
								<p>
									<span style="color: rgb(0, 0, 0)"
										>*The servers you're testing with, are the servers you're streaming from, this will show an accurate picture on how your network can handle
										{{ $store.state.brandInfo.appName }}
										service.</span
									>
								</p>
								<p>
									<br /><span style="color: rgb(97, 189, 109)"><strong>Third section: </strong></span
									><span style="color: rgb(0, 0, 0)">The results, here we will post our recommendation for you based on the speed results you received.</span>
								</p>
								<p>
									<strong>*Speed-test of {{ $store.state.brandInfo.appName }} LIVE &amp; VOD are limited to 50Mbit.</strong>
								</p>
								<p><br /></p>
								<p><strong>Please be aware that you must get a minimum of 20Mbit bandwidth in order to use our service without interruptions.</strong></p>
								<p><br /></p>
								<p>
									<u><strong>IMPORTANT:</strong></u
									><strong> </strong>There are certain situations where you will receive 20Mbit/s and still experience issues.
								</p>
								<p>These situations will require usage of our recommended VPNs. Please see below for more information.</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(24, 50, 71);
										font-family: -apple-system, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									<br style="box-sizing: border-box" />
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(24, 50, 71);
										font-family: -apple-system, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									<span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>Please use a VPN as a resolution to any buffering/freezing that you may experience.</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>Internet service providers are throttling and slowing down connections to our servers which leads to buffering and freezing issues you may
										experience. This isn’t something that can be fixed from our end and can happen at anytime even if it worked perfectly for you without a VPN
										in the past. You can rest assured that it is our mission to provide you with the best service available and we continuously keep our servers
										in tip top shape to ensure all our customers receive only the best</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										><strong><u>Commonly Asked Questions:</u></strong></span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>—————————————-</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										><strong>Q: What is a VPN?</strong></span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>A: A VPN disguises your internet network from your internet provider in order to stop them from seeing what you use your internet for and
										how you choose to use your internet.</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>—————————————</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										><strong>Q: I called my internet provider and they told me that they do not throttle my internet or slow down my speeds?</strong></span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>A: we can assure you that after providing support for many customers we can determine that internet providers do not like to advertise or
										admit to their customers that they throttle their speeds. After connecting a VPN the issue almost magically disappears which clearly
										displays that the internet provider is throttling the speed.</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>—————————————-</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										><strong>Q:But my HULU, Amazon, Netflix accounts work just fine without buffering?</strong></span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>A: This is specific to our servers and a VPN is the only solution to stop your internet provider from slowing down your speeds when using
										our service.</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>—————————————-</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										><strong>Q: What VPN’s do you recommend?</strong></span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>A: Our Recommended VPN providers are</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										><strong>-Ipvanish</strong></span
									><strong
										><br
											style="
												box-sizing: border-box;
												-webkit-font-smoothing: antialiased;
												color: rgb(32, 31, 30);
												font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
												font-size: 14.6667px;
												font-weight: 400;
												text-align: start;
												text-indent: 0px;
											" /></strong
									><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										><strong>-Surfshark</strong></span
									><strong
										><br
											style="
												box-sizing: border-box;
												-webkit-font-smoothing: antialiased;
												color: rgb(32, 31, 30);
												font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
												font-size: 14.6667px;
												font-weight: 400;
												text-align: start;
												text-indent: 0px;
											" /></strong
									><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										><strong>-Nord VPN</strong></span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>—————————————-</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										><strong>Q: How can I install a VPN?</strong></span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>A: Visit our recommended VPN provider’s website for detailed installation instructions guides for all of your devices.</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>Additional how-to installation videos are available on YouTube.</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>——————————————-</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										><strong>Q: I’m already using a VPN and still experiencing issues.</strong></span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>A: Not all VPN providers are created equal. Please choose a VPN from our recommended VPN providers listed above. If you are already using
										one of our recommended VPN providers and still experiencing buffering, please try changing the server location within the VPN.</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>Suggested VPN server locations are</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>-USA</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>-Canada</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>-Denmark</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>-Sweden</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>-Switzerland</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>-Norway</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>-Latvia</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>-Finland</span
									><br
										style="
											box-sizing: border-box;
											-webkit-font-smoothing: antialiased;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
										"
									/><span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>You will need to test multiple server locations in order to find the best one for your location.</span
									>
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(24, 50, 71);
										font-family: -apple-system, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									<span
										style="
											box-sizing: border-box;
											color: rgb(32, 31, 30);
											font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, system-ui, Roboto, 'Helvetica Neue', sans-serif;
											font-size: 14.6667px;
											font-weight: 400;
											text-align: start;
											text-indent: 0px;
											display: inline !important;
										"
										>See your VPN providers website for information on how to switch locations on the VPN.</span
									>
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(24, 50, 71);
										font-family: -apple-system, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									<br style="box-sizing: border-box" />
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(24, 50, 71);
										font-family: -apple-system, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									<span style="box-sizing: border-box; font-size: 14px"
										><strong style="box-sizing: border-box; font-weight: 700"><u style="box-sizing: border-box">How to run speedtest</u></strong> (
										{{ $store.state.brandInfo.appName }} app)</span
									>
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(24, 50, 71);
										font-family: -apple-system, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									<a :href="$store.state.brandInfo.speedTestLink" style="box-sizing: border-box; color: rgb(44, 92, 197)"
										><span style="box-sizing: border-box; font-size: 16px">How to run Speedtest</span></a
									>
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(24, 50, 71);
										font-family: -apple-system, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-weight: 400;
										text-align: start;
										text-indent: 0px;
									"
								>
									<br />
								</p>
								<p>
									If after trying the above steps you're getting speeds higher than 20Mbit, and you're having difficulties, please take a screenshot or clear
									picture of the the speedtest and email us the screenshot to
									<a data-fr-linked="true" href="/cdn-cgi/l/email-protection#3744424747584543775647585b5b585045584247194341"
										><span class="__cf_email__" data-cfemail="fb888e8b8b94898fbb9a8b949797949c89948e8bd58f8d">[email&#160;protected]</span></a
									>.
								</p>
								<p><br /></p>
								<p><br /></p>
								<p></p>
							</article>

							<!-- FINISH HERE -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'whatInternetBandwidthSpeedDoINeed',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped></style>
